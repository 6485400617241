<template>
    <div class="container">
      <div class="pageTitle">
        <div @click="backs"><img src="@/assets/back.png" alt="" srcset="" /></div>
  
        确认订单1
      </div>
      <div class="accountnu">购买账号：{{ geTel(query.userPhone) }}</div>
      <div class="info">
        <div class="name">{{ query.proName }}</div>
        <div class="num">
          <div class="total">
            <div class="username">{{ query.pname }}</div>
            <div v-if="query.crm_weukql">共{{ query.crm_weukql }}课时</div>
          </div>
          <span>
            <span class="market_price" v-if="showmark(query.market_price)">
              ¥{{ query.market_price }}x 1
            </span>
            ¥{{ query.price }}x 1
          </span>
        </div>
      </div>
      <div class="time">
        <span>有效期</span>
        一年
      </div>
      <!-- {{noUseRecord3.length}}--- -->
      <div class="time zhek" @click="coupon(3)" v-if="noUseRecord3.length > 0">
        <span>订单折扣</span>
        <div>
          <template v-if="$route.query.iszk == 1 && usecoupon3()">
            {{ usecoupon3().coupon_title }}
          </template>
          <template v-else> 共{{ noUseRecord3.length }}折扣券可用 </template>
  
          <img src="@/assets/more.png" alt="" srcset="" />
        </div>
      </div>
      <div class="time zhek no" v-else>
        <span>订单折扣</span>
        <div>暂无可用</div>
      </div>
      <div class="time zhek" @click="coupon(0)" v-if="noUseRecord.length > 0">
        <span>订单优惠</span>
        <div>
          <template v-if="$route.query.isRecord == 1 && usecoupon()">
            {{ usecoupon().coupon_title }}
          </template>
          <template v-else> 共{{ noUseRecord.length }}优惠券可用 </template>
  
          <img src="@/assets/more.png" alt="" srcset="" />
        </div>
      </div>
      <div class="time zhek no" v-else>
        <span>订单优惠</span>
        <div>暂无可用</div>
      </div>
      <div class="pay">
        <span>应付金额</span>
        ¥ {{ endPrice(query.price) }}
      </div>
      <div class="tips">目前仅支持微信支付</div>
      <div class="bt">
        <div @click="buyProductfn">提交订单</div>
      </div>
    </div>
  </template>
  <script>
  import Vconsole from 'vconsole'

let vConsole = new Vconsole()
  import { Toast } from "vant";
  import {
    receiveTryProduct,
    pay,
    paystatus,
    scrmGetOpenid,
    hasProductOrder,
    getNoUseRecord,
    buyProduct,
  } from "../utils/api";
  import axios from "axios";
  export default {
    name: "crmpay",
    data() {
      return {
        query: this.$route.query,
        noUseRecord: [], //优惠
        noUseRecord3: [], //折扣
        couponNum: 1, //折扣  3
        couponIndex: this.$route.query.cindex,
        discount: 0, //优惠   0
        discountIndex: this.$route.query.dindex,
        api: "/api",
        obj: JSON.parse(localStorage.getItem("urlkey")) || null,
        objs: {},
        payobj: {},
        paytimer: null,
        openid: localStorage.getItem("openid") || 0,
        buyTimer: null,
        zkid: null,
        yhid: null,
        noyouhui: 221,
      };
    },
    watch: {},
    methods: {
      // 优惠
      usecoupon() {
        // noUseRecord  0
        // noUseRecord3  3
        var obj = null;
        var arr = this.noUseRecord;
        arr.map((item, index) => {
          if (item.id == this.yhid) {
            obj = item;
          }
        });
        return obj;
      },
      // 折扣
      usecoupon3() {
        var arr = this.noUseRecord3;
        var obj = null;
        arr.map((item, index) => {
          if (item.id == this.zkid) {
            obj = item;
          }
        });
  
        return obj;
      },
      backs() {
        let obj = JSON.parse(localStorage.getItem("urlkey"));
        this.$router.push({
          path: "/crmShare",
          query: {
            code: obj.code,
            tel: obj.tel,
            id: obj.id,
            product_id: obj.product_id,
            isShare: obj.isShare,
            username: obj.username,
            product_name: obj.product_name,
            is_try: obj.is_try,
          },
        });
  
        console.log("obj", obj);
      },
      showmark(val) {
        let flg = false;
        if (val) {
          flg = true;
          if (val == "0.00" || val == "0") {
            flg = false;
          }
        }
        return flg;
      },
      is_weixn() {
        var isMobile = window.navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        ); // 是否手机端
        var isWx = /micromessenger/i.test(navigator.userAgent); // 是否微信
        var isComWx = /wxwork/i.test(navigator.userAgent); // 是否企业微信
        var PCFlag = false;
        if (isComWx && isMobile) {
          //手机端企业微信
          // console.log(‘手机端企业微信’);
          PCFlag = false;
        } else if (isComWx && !isMobile) {
          //PC端企业微信
          // console.log(‘PC端企业微信’);
          PCFlag = false;
        } else if (isWx && isMobile) {
          // 手机端微信
          // console.log(‘手机端微信’);
          PCFlag = true;
        } else if (isWx && !isMobile) {
          // PC端微信
          // console.log(‘PC端微信’);
          PCFlag = true;
        }
        return PCFlag;
      },
      // type 0优惠券   3折扣   创建订单
      // isRecord  //0 取消优惠券 1使用
      //iszk//0 取消折扣 1使用
      // noUseRecord: [], //优惠
      // noUseRecord3: [], //折扣
      coupon_idfn() {
        //优惠 iszk
        let path = this.$route.query;
        var num = 0;
        // console.log(this.usecoupon(),"++++89+++++++++")
        let ids = this.usecoupon() ? this.usecoupon().id : 0;
        // debugger;
        if (path.isRecord == 1) {
          num = this.noUseRecord.length > 0 ? ids : 0;
        } else {
          num = 0;
        }
        return num;
      },
      // 折扣
      coupon_discount_idfn() {
        let path = this.$route.query;
        var num = 0;
        let ids = this.usecoupon3() ? this.usecoupon3().id : 0;
        if (path.iszk == 1) {
          num = this.noUseRecord3.length > 0 ? ids : 0;
        } else {
          num = 0;
        }
        return num;
      },
      buyProductfn() {
        //  this.discount = Number(res.data[this.discountIndex].coupon_price);
  
        let path = this.$route.query;
        let parms = {
          phone: path.userPhone,
          code: this.obj.code,
          user_name: decodeURIComponent(path.username),
          coupon_id: this.coupon_idfn(), //优惠券
          coupon_discount_id: this.coupon_discount_idfn(), //折扣券
        };
        console.log(this.obj, parms, "444444");
        buyProduct(parms).then((res) => {
          if (res.code == 0) {
            this.payobj = res.data;
            console.log(this.payobj, "+++++++++++99999+");
            this.orderPay();
          } else if (res.code == 200001) {
            Toast(res.message);
            this.buyTimer = setTimeout(() => {
            //   this.$router.replace({
            //     path: "/newpayend",
            //     query: {
            //       type: 1,
            //     },
            //   });
            window.location.href = "https://h5.tinggupiao.com.cn/newpayend?type=1"
            }, 1000);
          } else {
            Toast(res.message);
          }
          console.log();
        });
      },
  
      orderPay() {
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          console.log("ios啊-----是否在微信", this.is_weixn());
          if (this.is_weixn()) {
            this.ajaxpay("post", "weixin_js");
          } else {
            this.ajaxpay();
          }
        } else if (/(Android)/i.test(navigator.userAgent)) {
          //判断Android
          console.log("Android-----是否在微信", this.is_weixn());
          if (this.is_weixn()) {
            this.ajaxpay("post", "weixin_js");
          } else {
            this.ajaxpay();
          }
        }
      },
      ajaxpay(type, url) {
        let that = this;
        Toast.loading({
          message: "支付中...",
          forbidClick: true,
          overlay: true,
        });
        // weixin_wap
        var urls = null;
        console.log(this.payobj.id, "id在这里啊");
        if (this.is_weixn()) {
          urls = `${this.api}/orders/${this.payobj.id}/pay?channel=weixin_js&openid=${this.openid}`;
        } else {
          urls = `${this.api}/orders/${this.payobj.id}/pay?channel=weixin_wap`;
        }
  
        axios
          .request({
            url: urls,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              // referer: "https://pay.tinggupiao.com.cn",
            },
            method: type ? type : "get",
          })
          .then((res) => {
            if (!url) {
              // let a = document.createElement("a"); //创建一个a标签元素
              // a.style.display = "none"; //设置元素不可见
              // a.href = res.data.payable; //设置下载地址
              // document.body.appendChild(a); //加入
              // a.click(); //触发点击,下载
              window.location.href = res.data.payable;
              Toast.clear();
              // setTimeout(() => {
              // document.body.removeChild(a);
  
              that.paytimer = setInterval(() => {
                that.paystatusfn();
              }, 2000);
  
              that.close();
            } else {
              WeixinJSBridge.invoke(
                "getBrandWCPayRequest",
                {
                  appId: res.data.payable.appId,
                  timeStamp: res.data.payable.timeStamp,
                  nonceStr: res.data.payable.nonceStr,
                  package: res.data.payable.package,
                  signType: "MD5",
                  paySign: res.data.payable.paySign,
                },
                function (res) {
                  WeixinJSBridge.log(res.err_msg);
                  Toast.clear();
                  that.paytimer = setInterval(() => {
                    that.paystatusfn();
                  }, 2000);
  
                  if (res.err_msg == "get_brand_wcpay_request:ok") {
                    // alert("微信支付成功!");
                  } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
                    // alert("用户取消支付!");
                  } else {
                    // alert("支付失败!");
                  }
                }
              );
            }
          })
          .catch(function (error) {
            //console.log(error.log)
          });
      },
      paystatusfn() {
        // 订单状态 0待支付 1已支付 2已取消 3已退款
        axios
          .request({
            url: `${this.api}/orders/${this.payobj.no}/status`,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "get",
          })
          .then((res) => {
            console.log(res.data, "99999999999999");
            // this.payStatus = res.data.state;
            if (res.data.state == 1) {
              clearTimeout(this.paytimer);
              document.title = "";
            //   this.$router.replace({
            //     path: "/newpayend",
            //     query: {
            //       type: 1,
            //     },
            //   });
            window.location.href = "https://h5.tinggupiao.com.cn/newpayend?type=1"
            }
          })
          .catch(function (error) {
            //console.log(error.log)
          });
      },
      endPrice(val) {
        let nums = (Number(val) * this.couponNum).toFixed(2);
  
        var num = nums - this.discount;
        var price = 0.01;
        if (num < 0.01) {
          num = 0.01;
        }
        return num.toFixed(2);
      },
      geTel(tel) {
        if (tel) {
          var reg = /^(\d{3})\d{4}(\d{4})$/;
          return tel.replace(reg, "$1****$2");
        }
      },
      getNoUseRecordfn() {
        // type 0优惠券
        getNoUseRecord({
          phone: this.query.userPhone,
          type: 0,
          is_use_coupon: this.query.is_use_coupon,
          product_id: this.query.product_id,
        }).then((res) => {
          let path = this.$route.query;
          if (res.data.length > 0) {
            if (path.yhid || path.yhid != undefined) {
              this.yhid = path.yhid;
            } else {
              this.yhid = res.data[0].id;
            }
          } else {
            this.isRecord = 0;
          }
          this.noUseRecord = res.data;
  
          // isRecord  0 取消优惠   1  使用优惠
          // discount: 优惠    couponNum 折扣
          if (path.isRecord == 0) {
            this.discount = 0;
          } else {
            if (res.data.length > 0) {
              if (this.usecoupon()) {
                if (path.isRecord) {
                  this.discount = Number(this.usecoupon().coupon_price);
                } else {
                  this.discount = 0;
                }
              } else {
                this.discount = 0;
              }
            }
          }
        });
      },
      getNoUseRecordfn3() {
        //3折扣券
        getNoUseRecord({
          phone: this.query.userPhone,
          type: 3,
          is_use_coupon: this.query.is_use_coupon,
          product_id: this.query.product_id,
        }).then((res) => {
          let path = this.$route.query;
          if (res.data.length > 0) {
            if (path.zkid || path.zkid != undefined) {
              this.zkid = path.zkid;
            } else {
              this.zkid = res.data[0].id;
            }
  
            // iszk  0 取消折扣   1  使用折扣
            // discount: 优惠    couponNum 折扣
            //        zkid:null,
            // yhid:null
          } else {
            this.iszk = 0;
          }
          this.noUseRecord3 = res.data;
          if (path.iszk == 0) {
            this.couponNum = 1;
          } else {
            if (res.data.length > 0) {
              if (this.usecoupon3()) {
                if (path.iszk) {
                  this.couponNum = Number(this.usecoupon3().coupon_price);
                } else {
                  this.couponNum = 1;
                }
              } else {
                this.couponNum = 1;
              }
            }
          }
        });
      },
      dindexfn() {
        let path = this.$route.query;
        let num = null;
        num = path.dindex
          ? path.dindex
          : this.noUseRecord.length > 0
          ? this.noUseRecord[0].id
          : 0;
        return num;
      },
      cindexfn() {
        let path = this.$route.query;
        let num = null;
        num = path.cindex
          ? path.cindex
          : this.noUseRecord3.length > 0
          ? this.noUseRecord3[0].id
          : 0;
        return num;
      },
      coupon(val) {
        // 3  折扣   0优惠
        // if (this.obj.product_id == this.noyouhui) {
        //   Toast("本产品不享受优惠");
        //   return;
        // }
        // debugger
        let path = this.$route.query;
  
        this.$router.push({
          path: "/coupon",
          query: {
            type: val,
            userPhone: path.userPhone,
            crm_weukql: path.crm_weukql,
            price: path.price,
            pname: path.pname,
            is_use_coupon: path.is_use_coupon,
            market_price: path.market_price,
            username: path.username,
            cindex: this.cindexfn(), // 3
            dindex: this.dindexfn(), //0
            openid: this.openid ? this.openid : 0,
            isRecord: path.isRecord,
            iszk: path.iszk,
            zkid: this.zkid,
            yhid: this.yhid,
            product_id: path.product_id,
          },
        });
      },
    },
    mounted() {},
    destroyed() {
      clearTimeout(this.buyTimer);
    },
    mounted() {
      console.log(
        this.is_weixn(),
        !this.openid || this.openid == "undefined",
        this.openid,
        "++++++++++zzzz"
      );
      console.log(
        !this.openid || this.openid == "undefined",
        "++++++++++jjjjjjjjjjj"
      );
      if (this.is_weixn()) {
        //判断地址栏是否有openid如果有则直接支付
  
        //调用支付接口传递openid
        if (!this.openid || this.openid == "undefined") {
          // this.codefn();
          // window.location.href=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc87c83d8f8a89e1e&redirect_uri=https%3A%2F%2Fp.wx.stonecx.com%2Fapi%2Fwechat%2Fcallback&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
          console.log("href before");
          window.location.href = `https://p.wx.stonecx.com/api/wechat/callback?callback=${encodeURIComponent(
            window.location.href
          )}`;
          console.log("href after", window.location.href);
          let url = window.location.href;
          var search = url.split("?")[1];
          var t = search.split("&");
          var key = {};
          t.map((item, index) => {
            var b = item.split("=");
            key[b[0]] = b[1];
          });
          this.openid = key.openid;
          localStorage.setItem("openid", key.openid);
        }
      }
    },
    created() {
      this.couponIndex = this.$route.query.cindex;
      // discount: 0, //优惠   0
      this.discountIndex = this.$route.query.dindex;
  
      let url = window.location.href;
      var search = url.split("?")[1];
      var t = search.split("&");
      var key = {};
      t.map((item, index) => {
        var b = item.split("=");
        key[b[0]] = b[1];
      });
      this.objs = key;
      console.log(this.objs.is_use_coupon, "99999", this.obj);
      // 0都可 1可以折扣 2可以优惠券 3都不可
      if (this.objs.is_use_coupon == 0) {
        this.getNoUseRecordfn(); // 优惠
        this.getNoUseRecordfn3(); //3折扣券
      } else if (this.objs.is_use_coupon == 1) {
        this.getNoUseRecordfn3(); //3折扣券
      } else if (this.objs.is_use_coupon == 2) {
        this.getNoUseRecordfn(); // 优惠
      } else if (this.objs.is_use_coupon == 4) {
        // 只可使用自生产品绑定优惠卷
        this.getNoUseRecordfn();
      } else if (this.objs.is_use_coupon == 5) {
        // 只可使用自生产品绑定折扣券
        this.getNoUseRecordfn3();
      } else if (this.objs.is_use_coupon == 6) {
        // 只可使用自生产品绑定优惠卷和折扣券
        this.getNoUseRecordfn(); // 优惠
        this.getNoUseRecordfn3(); //3折扣券
      }
      // this.getNoUseRecordfn(); // 优惠
      // this.getNoUseRecordfn3(); //3折扣券
  
      console.log(key, "89555", this.obj);
    },
  };
  </script>
  <style scoped lang="scss">
  .container {
    width: 100vw;
    height: 100vh;
    background: #f8f8f8;
    .pageTitle {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      // font-weight: 400;
      color: #333333;
      justify-content: center;
      position: relative;
      width: 100%;
      height: 44px;
      // background: #fff;
      div {
        position: absolute;
        left: 16px;
        // background: red;
        display: flex;
        align-items: center;
        padding-right: 10px;
      }
      img {
        width: 12px;
        height: 44px;
      }
    }
    .accountnu {
      width: 100%;
      height: 54px;
      background: #ffffff;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      // font-weight: 400;
      color: #333333;
      padding-left: 16px;
      box-sizing: border-box;
    }
    .info {
      width: 100%;
      height: 103px;
      background: #ffffff;
      padding-left: 16px;
      box-sizing: border-box;
      margin-top: 8px;
      .name {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        // font-weight: 500;
        color: #333333;
        line-height: 26px;
        padding-top: 20px;
      }
      .num {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        // font-weight: 400;
        color: #999999;
        line-height: 20px;
        padding-right: 16px;
        padding-top: 17px;
        .total {
          display: flex;
        }
        .username {
          margin-right: 12px;
        }
      }
    }
    .time {
      width: 100%;
      height: 60px;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 8px;
      padding: 0 16px;
      box-sizing: border-box;
      span {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        // font-weight: 400;
        color: #333333;
      }
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      // font-weight: 400;
      color: #999999;
    }
    .zhek {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      // font-weight: 400;
      color: #ef0923;
      margin-top: 1px;
  
      div {
        display: flex;
        align-items: center;
        img {
          width: 8px;
          height: 24px;
          display: block;
          margin-left: 8px;
        }
      }
    }
    .pay {
      width: 100%;
      height: 70px;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      box-sizing: border-box;
      margin-top: 8px;
      span {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        // font-weight: 400;
        color: #333333;
      }
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      // font-weight: 500;
      color: #ef0923;
    }
    .tips {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      // font-weight: 400;
      color: #999999;
      margin-top: 8px;
      padding: 0 16px;
      box-sizing: border-box;
    }
    .bt {
      width: 100%;
      height: 68px;
      background: #ffffff;
      position: fixed;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        width: 343px;
        height: 44px;
        background: #ef0923;
        border-radius: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        // font-weight: 500;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .no {
      div {
        color: #ccc !important;
      }
    }
    .market_price {
      color: red !important;
      text-decoration: line-through;
      margin-right: 10px;
    }
  }
  </style>
  